import React  from 'react';
import './Celebration.css';

export default function Celebration(imgToShow) {

    var img = Object.values(imgToShow)[0][0].imgToShow;

 
    return (
        <div>
            <div className="celebration animate__animated animate__zoomInDown" id="imgDiv">
                <img src={"/img/reactions/" + img } height="500" width="500" alt="Agreed!"></img>
                {/* <Giphy tag="hurray" /> */}
            </div>
        </div >
    )
}