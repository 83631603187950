
import './App.css';

import Loader from './components/Loader/Loader.js';
import Channel from './components/Channel/Channel.js';
import DeleteOldMessages from './components/DeleteOldMessages/DeleteOldMessages.js';
import Cookies from 'js-cookie'

import React from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { useAuthState } from './hooks';

import files from './components/Celebration/files.json'
import goods from "./build-info.json"

function App() {

  var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const { user, initializing } = useAuthState(firebase.auth());

  const signInWithGoogle = async () => {
    // Retrieve Google provider object
    const provider = new firebase.auth.GoogleAuthProvider();
    // Set language to the default browser preference
    firebase.auth().useDeviceLanguage();
    // Start sign in process
    try {
      await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      console.log(error.message);
    }
  };


  const signOut = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.log(error.message);
    }
  };

  const helpBanner = () => {
    var x = document.getElementById("helpcard");
    if (x.style.display === "none") {
      x.style.display = "block";
      setTimeout(function () { x.style.display = 'none' }, 5000);
    } else {
      x.style.display = "none";
    }
  };


  const imgOrder = files.agreement;

  const viewonlymode = () => {
    var url = window.location.href
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    let slug = queryString;

    if (slug === "viewonly") {
      Cookies.set('mode', 'viewonly', { expires: 14 })
      return true;
    }

    try {
      if (document.getElementById("view_only_checkbox").checked) {
        window.location.href = window.location.href + "?viewonly";
        Cookies.set('mode', 'viewonly', { expires: 14 })
        return true;
      }
    } finally {
      return false;
    }

  }

  const viewOnlyRedir = () => {
    let viewOnlyVal = Cookies.get('mode')

    var url = window.location.href
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    let slug = queryString;

    if (slug === "viewonly" && !viewOnlyVal) {
      window.location.href = window.location.origin;
    }

    if (slug === "viewonly") {
      return;
    }

    if (viewOnlyVal === "viewonly") {
      window.location.href = window.location.href + "?viewonly";
    }
  }

  function getRoom() {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      return hash;
    }
  }

  var inGoodRoom = false;

  if (getRoom() !== 'pickroom') {
    inGoodRoom = true;
  }

  function roomformSubmit(event) {
    let newUrl = window.location.href = "/#" + document.getElementById('roomInput').value;
    window.location = newUrl;
    window.location.reload();
    event.preventDefault();
  }

  const BuildInfo = () => {
    return Object.keys(goods).map((innerAttr, index) => {
      return (
        <div className="sha" key={goods[innerAttr]}>
          <span>&nbsp;build {goods[innerAttr]}</span>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (initializing) {
      return (
        <div className="flex items-center justify-center h-full">
          <Loader size="lg" />
        </div>
      );
    }

    viewOnlyRedir()

    if (user && !inGoodRoom) return (
      <div className="loginDiv">
        <div className="row">
          <div className="bigBreak"></div>
          <div>
            <div className="form-group">
              <label htmlFor="paperInputs1">Looks like you're logged in, but not in a room!</label>
              <div className="bigBreak"></div>
              <label htmlFor="paperInputs1">Enter a room name below!</label>
              <div className="bigBreak"></div>
              <form onSubmit={roomformSubmit}>
                <input className="input-block" type="text" placeholder="myteamname" id="roomInput" data-value-field="value" required pattern="^[a-z]+$"></input>
                <div className="text-muted">lowercase letters only (^[a-z]+$)</div>
                <div className="bigBreak"></div>
                <button
                  type="submit">
                  Enter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );


    if (user && inGoodRoom) return <Channel user={user} imgOrder={imgOrder} viewOnly={viewonlymode()} />;

    Cookies.remove('mode')

    return (
      <div className="loginDiv">
        <div className="row">
          <div>
            <img src="/img/logo.png" alt="PlanningPoker.guru" className="bigLogo"></img>
          </div>
          <div className="break"></div>
          <div className="bigLogo">
            PlanningPoker.guru
          </div>
          <div className="break"></div>
          <div>
            <p className="modal-text">This is a <a href="https://en.wikipedia.org/wiki/Planning_poker">planning
              poker</a> app meant to be used by teams over video chat. </p>
            <p className="modal-text">Everyone who joins will have a card and can click the numbered buttons to
              assign
              their card a value. </p>
            <p className="modal-text">Once done discussing a ticket, everyone can click their point prediction
              and
              everyone else will see it.</p>
          </div>
          <div className="break"></div>
          <div>
            <button
              onClick={signInWithGoogle}>
              <img src="/img/signInWithGoogle.png" alt="Sign In With Google" className="bigLogo"></img>
            </button>
            <div>&nbsp;</div>
            <div>
              <div>
                <fieldset className="form-group">
                  <label htmlFor="view_only_checkbox" className="paper-check">
                    <input type="checkbox" name="view_only_checkbox" id="view_only_checkbox" value="opton 1" />
                    <span>View Only</span>
                  </label>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper">

      <header className="header">
        <div>
          <span>
            <img src="/img/navlogo.png" alt="Nav Logo"></img>
          </span>
          <span className="navText">
            PlanningPoker.guru
          </span>

        </div>

        <div>
          <button onClick={helpBanner} popover-bottom="Help" className="help"> <span className="material-icons">help_outline</span></button>
          <button onClick={signOut} popover-bottom="Logout" className="logoutbutton"> <span className="material-icons">logout</span></button>
        </div>
      </header>
      <section className="content">
        <div className="columns">
          <main className="main">
            <section>
              <div className="card helpcard" id="helpcard">
                <div className="card-body">
                  <div>
                    <span className="material-icons helpicon">keyboard</span>
                    Keyboard support now available! Try pressing 1, 2, 3, 5, 8, t or d
                  </div>
                  <div>
                    <span className="material-icons helpicon">cookie</span>
                    View Only mode now remembered via cookies
                  </div>
                </div>
              </div>
              {renderContent()}
            </section>
          </main>
          <aside className="sidebar-first"></aside>
          <aside className="sidebar-second"></aside>
        </div>
      </section>
      <footer className="footer">
        <BuildInfo />
        {user ? <DeleteOldMessages></DeleteOldMessages> : null}</footer>
    </div>

  );
}


export default App;
