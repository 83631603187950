import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useFirestoreQuery } from '../../hooks';
// Components
import Message from '../Message/Message.js';
import Celebration from '../Celebration/Celebration.js';
import './Channel.css';



function getRoom(){
  if (window.location.hash) {
    var hash = window.location.hash.substring(1);
    return hash;
  }
}

if (!getRoom()){
  window.location.href = window.location.href = "/#pickroom";
  window.location.reload();
}

const room = getRoom();

const Channel = ({ user = null, imgOrder = null, viewOnly = null }) => {
  const { uid, displayName, photoURL } = user;
  const db = firebase.firestore();
  const messagesRef = db.collection(room);

  const messages = useFirestoreQuery(
    messagesRef.orderBy('createdAt', 'desc').limit(50)
  );

  var latestPerUser = {};
  var syncReveal = false;
  var timebox = false;

  messages.forEach(function (item, index) {
    if (!(item.uid in latestPerUser)) {
      latestPerUser[item.uid] = item;
    }
  });

  var messagesLatestPerUser = [];

  for (const [key, value] of Object.entries(latestPerUser)) {
    if (!key) { }
    messagesLatestPerUser.push(value);
  }

  messagesLatestPerUser.sort((a, b) => (a.uid > b.uid) ? 1 : -1)

  var missingMessage = true;

  for (const [key, value] of Object.entries(messagesLatestPerUser)) {
    if (!key) { }
    if (value.uid === user.uid) {
      missingMessage = false;
    }
  }


  if (missingMessage && !viewOnly) {
    messagesRef.add({
      text: "---",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      displayName,
      photoURL,
      imgToShow: "..."
    });
  }


  const inputRef = useRef();
  const bottomListRef = useRef();

  function everyoneAgrees(messageList) {

    if (!messageList) {
      return;
    }

    var justScores = [];
    messageList.forEach(function (item, index) {
      if (item != null && item.text.length < 4) {
        justScores.push(item.text)
      }
    });

    console.log(justScores)

    if (justScores.length < 2) {
      return;
    }

    if (justScores.includes("--")) {
      return;
    }

    if (justScores.includes("---")) {
      return;
    }

    if (justScores.every((val, i, arr) => val === arr[0])) {
      return true;
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleOnChange = e => {
    if (e === "del") {
      document.getElementById("timebox_checkbox").checked = false;
      messagesRef.get().then(function (querySnapshote) {
        querySnapshote.forEach(element => {
          element.ref.delete();
        });
      })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      return;
    }

    const shuffleArray = array => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }

    const imgToShow = shuffleArray(imgOrder)[0];


    syncReveal = true;
    timebox = document.getElementById("timebox_checkbox").checked;

    messagesRef.add({
      text: e,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      displayName,
      photoURL,
      imgToShow,
      syncReveal,
      timebox,
    });
  };

  function shouldHideScores(messagesLatestPerUser) {

    var hasTrue = true;
    var justScores = [];
    var hasTwoDashes = false;
    var hasThreeDashes = false;

    messagesLatestPerUser.forEach(function (item, index) {
      if (item != null) {
        justScores.push(item.text)
      }
    });

    if (justScores.includes("--")) {
      hasTwoDashes = true;
    }

    if (justScores.includes("---")) {
      hasThreeDashes = true;
    }

    if (!hasTwoDashes && !hasThreeDashes) {
      return false;
    }

    return hasTrue;
  }

  const viewOnlyHidden = viewOnly ? "hidden" : null;

  function keyPress(e) {
    if (e.key === '1') {
      handleOnChange("1")
    }
    if (e.key === '2') {
      handleOnChange("2")
    }
    if (e.key === '3') {
      handleOnChange("3")
    }
    if (e.key === '5') {
      handleOnChange("5")
    }
    if (e.key === '8') {
      handleOnChange("8")
    }


    if (e.key === 'd') {
      handleOnChange("del")
    }

    if (e.key === 't') {
      if (document.getElementById("timebox_checkbox").checked !== true) {
        document.getElementById("timebox_checkbox").checked = true;
      } else {
        document.getElementById("timebox_checkbox").checked = false;
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  });

  return (
    <div className="flex">
      {everyoneAgrees(messagesLatestPerUser) ? <Celebration imgToShow={messagesLatestPerUser}></Celebration> : null}

      <div>
        {messagesLatestPerUser
          ?.map(message => (
            <span key={message.id}>
              <Message {...message} hideScores={shouldHideScores(messagesLatestPerUser)} />
            </span>
          ))}

        <div ref={bottomListRef} />
      </div>

      <div className={`mb-8 mx-6 buttonDiv ${viewOnlyHidden}`}>
        <button
          onClick={() => handleOnChange("1")}
          value="1"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          1
        </button>

        <button
          onClick={() => handleOnChange("2")}
          value="2"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          2
        </button>

        <button
          onClick={() => handleOnChange("3")}
          value="3"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          3
        </button>

        <button
          onClick={() => handleOnChange("5")}
          value="5"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          5
        </button>

        <button
          onClick={() => handleOnChange("8")}
          value="8"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          8
        </button>

        <button
          onClick={() => handleOnChange("13")}
          value="8"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          13
        </button>

        <button
          onClick={() => handleOnChange("del")}
          value="del"
          className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
        >
          <span className="material-icons">delete_sweep</span>
        </button>

        <div>
          <fieldset className="form-group">
            <label htmlFor="timebox_checkbox" className="paper-check">
              <input type="checkbox" name="paperChecks" id="timebox_checkbox" value="opton 1" />
              <span>Timebox</span>
            </label>
          </fieldset>
        </div>

        <div>
          <button
            onClick={() => handleOnChange("think")}
            value="think"
            className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
          >
            Think
          </button>

          <button
            onClick={() => handleOnChange("dizzy")}
            value="dizzy"
            className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
          >
            Dizzy
          </button>

          <button
            onClick={() => handleOnChange("sleep")}
            value="sleep"
            className="btn-large p-2 mx-2 bg-white transition-all duration-75 ease-in-out text-xl"
          >
            Sleep
          </button>
        </div>
      </div>
    </div>

  );
};

Channel.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    displayName: PropTypes.string,
    photoURL: PropTypes.string,
    imgOrder: PropTypes.string,
  }),
};

export default Channel;
