import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Message.css';

const animateCSS = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(".animateid-" + element);

    if (node != null) {
      node.classList.add(`${prefix}animated`, animationName);
    }

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }
    if (node != null) {
      node.addEventListener('animationend', handleAnimationEnd, { once: true });
    }
  });

function messageParser(text, hideScores) {

  if (hideScores && !isNaN(text)) {
    return "?";
  }

  if (text === "think") {
    return <div className="card-message-img-think">&nbsp;</div>
  }

  if (text === "dizzy") {
    return <div className="card-message-img-dizzy">&nbsp;</div>
  }

  if (text === "sleep") {
    return <div className="card-message-img-sleep">&nbsp;</div>
  }

  return text;
}

function shouldTimebox(message, timebox, hideScores) {

  if (message === "?") {
    return false;
  }

  if (hideScores) {
    return false;
  }

  if (!timebox) {
    return false;
  }

  return true;
}

function kickMe(message) {

  if (message === "---") {
    return "x";
  }

}

const Message = ({
  text = '',
  displayName = '',
  photoURL = '',
  id = '',
  imgToShow = '',
  syncReveal = '',
  hideScores = '',
  timebox = '',
}) => {

  useEffect(() => {
    //console.log(id);
    animateCSS(id, 'bounce');
  }, [id]);

  if (!text) return null;

  return (
    <div className={"pointbox card animateid-" + id} >
      <div className={shouldTimebox(text, timebox, hideScores) ? "card-body timebox" : "card-body"}>
        <h4 className="card-title card-name">
          {displayName ? (
            <p>{displayName}</p>
          ) : null}

        </h4>
        <div className="card-text">
          <h1>{messageParser(text, hideScores)}</h1>
        </div>
        <div>{kickMe(text)}</div>
      </div>
      <div className="image-cropper">
        {photoURL ? (
          <img
            src={photoURL}
            alt={displayName}
            className="avatar"
          />
        ) :
          (
            <img
              src="/img/rock.png"
              alt={displayName}
              className="avatar"
            />
          )
        }
      </div>
    </div>
  );
};


Message.propTypes = {
  text: PropTypes.string,
  createdAt: PropTypes.shape({
    seconds: PropTypes.number,
  }),
  displayName: PropTypes.string,
  photoURL: PropTypes.string,
  id: PropTypes.string,
  imgToShow: PropTypes.string,
  syncReveal: PropTypes.bool,
  hideScores: PropTypes.bool,
  timebox: PropTypes.bool,
};

export default Message;
