import React from 'react';
import firebase from 'firebase/app';

const DeleteOldMessages = ({ user = null }) => {
    const db = firebase.firestore();

    var fiveHoursAgo = Date.now() - 1800000;
    var fiveHoursAgoDateObj = new Date(fiveHoursAgo);

    function getRoom() {
        if (window.location.hash) {
            var hash = window.location.hash.substring(1);
            return hash;
        }
    }

    const room = getRoom();

    db.collection(room).where("createdAt", "<", fiveHoursAgoDateObj)
        .get().then(function (querySnapshote) {
            querySnapshote.forEach(element => {
                element.ref.delete();
            });
        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
        });


    return (
        <button popover-top="Cleaned Up Old Messages!" className="logoutbutton">
            <span className="material-icons">cleaning_services</span>
        </button>
    );
};


export default DeleteOldMessages;
